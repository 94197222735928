import { faHouse, faBuilding } from "@fortawesome/free-solid-svg-icons"

export const propertyConfig: any = {
    propertyType: {
        house: {
            name: 'House',
            value: 'house',
            icon: faHouse
        },
        bungalow: {
            name: 'Bungalow',
            value: 'bungalow',
            icon: faHouse
        },
        maisonette: {
            name: 'Maisonette',
            value: 'maisonette',
            icon: faBuilding
        },
        apartment: {
            name: 'Apartment',
            value: 'apartment',
            icon: faBuilding
        },
        duplex: {
            name: 'Duplex Apartment',
            value: 'duplex',
            icon: faBuilding
        },
        studio: {
            name: 'Studio',
            value: 'studio',
            icon: faBuilding
        }
    }
}

export const enquiryStatus: { [key: string]: { name: string, value: string, acronim: string; } } = {
    notAssigned: { name: 'Not Assigned', value: 'notAssigned', acronim: 'NA' }, 
    assigned: { name:  'Assigned', value: 'assigned', acronim: 'AA' },
    approved: { name: 'Approved', value: 'approved', acronim: 'AP' },
    awaitingApproval: { name: 'Awaiting Approval', value: 'awaitingApproval', acronim: 'AW' },
    awaitingResponse: { name: 'Awaiting Response', value: 'awaitingResponse', acronim: 'AR' }, 
    offeredOut: { name: 'Offered Out', value: 'offeredOut', acronim: 'OO' }, 
    booked: { name: 'Booked', value: 'booked', acronim: 'BK' }, 
    complete: { name: 'Complete', value: 'complete', acronim: 'CP' },
    cancelled: { name: 'Cancelled', value: 'cancelled', acronim: 'CN' },
    notPossible: { name: 'Not Possible', value: 'notPossible', acronim: 'NP' }
}

export const bookingStatus: { [key: string]: { name: string, value: string} } = {
    none: { name: 'None', value: 'none' },
    active: { name: 'Active', value: 'active' },
    needsAttention: { name: 'Needs Attention', value: 'needsAttention' },
    checkIn: { name: 'Check In', value: 'checkIn' },
    checkOut: { name: 'Check Out', value: 'checkOut' },
    extended: { name: 'Extended', value: 'extended' }
}