import { type ReactNode, type ComponentPropsWithoutRef, type FC } from "react";
import classes from './IconRoundedButton.module.scss';

import Spinner from "../spinner/Spinner";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { type IconDefinition } from "@fortawesome/fontawesome-svg-core";
import classNames from "classnames";

type ButtonType = {
    mode: string;
    size?: string;
    isLoading?: boolean;
    children?: ReactNode;
    icon: IconDefinition;
    outline?: boolean;
    tooltip?: string;
} & ComponentPropsWithoutRef<'button'>;

const IconRoundedButton: FC<ButtonType> = ({ children, isLoading, mode, icon, size, tooltip, outline = false, ...props }) => {
    return (
        <button className={ classNames(classes[`btn-${mode}`], outline ? classes.outline : '', { [classes[`btn-${mode}--${size}`]]: size }) } {...props} disabled={ isLoading || props.disabled }>
            { tooltip && (
                <span style={{ minWidth: `${tooltip.length * 8}px`, maxWidth: 'fit-content' }} className={ classes[`btn-${mode}__tooltip`] }> { tooltip } </span>
            ) }
            { isLoading ? <Spinner /> : <FontAwesomeIcon icon={ icon } /> }
        </button>
    )
}

export default IconRoundedButton;