import { type FC, Fragment } from "react";
import { Link, useSearchParams } from "react-router-dom";

import classes from './Error.module.scss';

type ErrorsObjectProps = {[key: string]: { title: string; message: string; }}

const errors: ErrorsObjectProps = {
    timeout: {
        title: 'Request timed out',
        message: 'The server took too long to process the request. Please try again later.'
    },
    permissionDenied: {
        title: 'Permission Denied',
        message: 'You do not have the required access to perform this action.'
    }
}

const Error: FC = () => {
    const [searchParamas] = useSearchParams();
    const errorType = searchParamas.get('type');

    return (
        <div className={ classes.error }>
            <div className={ classes.error__container }>
                { errorType ? (
                    <Fragment>
                        <h1 className={ classes.error__heading }> { errors[errorType].title } </h1>
                        <p className={ classes.error__text }> { errors[errorType].message } </p>
                        <Link className={ classes.error__button } to="/dashboard">Home</Link>
                    </Fragment>
                ) : (
                    <Fragment>
                        <h1 className={ classes.error__heading }>Something went wrong</h1>
                        <Link className={ classes.error__button } to="/dashboard">Home</Link>
                    </Fragment>
                ) }
            </div>
        </div>
    )
}

export default Error;