import { type FC } from "react";
import classes from './Loader.module.scss';

const Loader: FC<{ displayText?: boolean; text?: string; }> = ({ displayText = true, text = 'Loading...' }) => {
    return (
        <div className={ classes['cw-loading-wrap'] }>
            <div className={ classes['cw-loading'] }>
                <div></div>
            </div>
            { displayText && <p className={ classes['cw-loading-text'] }> { text } </p> }
        </div>
    )
}

export default Loader;