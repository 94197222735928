import { FC } from "react";
import { useNavigate } from "react-router-dom";
import classes from './MyAccountDropdown.module.scss';

import { useCurrentUser } from "context/CurrentUserProvider";
import { useAuthContext } from "context/AuthContext";

import { CloseButton, Acronim, Image } from "components/shared";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightFromBracket, faArrowsRotate } from "@fortawesome/free-solid-svg-icons";

import { logOutCurrentAccount } from "service/account.service";
import { hasProfilePicture } from "utils";

import queryClient from "queryClient";
import classNames from "classnames";

const accountRoles: { [key: string]: { name: string; value: string; } } = {
    user: { name: 'User', value: 'user' },
    moderator: { name: 'Moderator', value: 'moderator' },
    admin: { name: 'Admin', value: 'admin' },
    masteradmin: { name: 'MasterAdmin', value: 'masteradmin' }
}

const MyAccountDropdown: FC<{ onClose: () => void }> = ({ onClose }) => {
    const navigate = useNavigate();
    const { currentUser } = useCurrentUser();
    const { onLogout } = useAuthContext();

    const handleClose = () => {
        onClose();
    }

    const handleLogOut = async () => {
        const res = await logOutCurrentAccount();
        if (res?.status === 200) {
            onLogout();
            navigate('/signin');
        } 
    }

    const handleRefreshData = async () => {
        queryClient.invalidateQueries({ queryKey: ['currentUserProvider'] });
    }

    if (!currentUser) return;

    return (
        <div className={ classes.dropdown }>
            <CloseButton onClick={ handleClose } />
            <div className={ classes.dropdown__container }>
                <div className={ classes.dropdown__header }>
                    <div className={ classes.dropdown__image }>
                    { hasProfilePicture(currentUser) ? (
                        <Image source={currentUser.imageUrl.key} alt={currentUser.fullName} />
                    ) : (
                        <Acronim name={ currentUser.fullName } color="rgb(189, 81, 83)" />
                    )
                    }
                    </div>
                    <div className={ classes.dropdown__name }>
                        <p> Hi, { currentUser?.firstName }! </p>
                    </div>
                    <div className={ classNames(classes.dropdown__status, classes[`dropdown__status--${currentUser.role}`]) }>
                        { accountRoles[currentUser.role].name }
                    </div>
                </div>
                <div className={ classes.dropdown__list }>
                    <a className={ classes.dropdown__link } onClick={ handleRefreshData }>
                        <FontAwesomeIcon icon={ faArrowsRotate } />
                        Refresh Data
                    </a>
                    <a className={ classes.dropdown__link } onClick={ handleLogOut }>
                        <FontAwesomeIcon icon={ faArrowRightFromBracket } />
                        Log Out
                    </a>
                </div>
            </div>
        </div>
    )
}

export default MyAccountDropdown;