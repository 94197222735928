import { type ReactNode, type FC } from "react";
import classes from './Disclaimer.module.scss';

import classNames from "classnames";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { type IconDefinition } from "@fortawesome/fontawesome-svg-core";

const disclaimerIcons: {[ key: string ]: IconDefinition } = {
    info: faCircleInfo
}

const Disclaimer: FC<{ children: ReactNode; mode: string; }> = ({ children, mode }) => {
    return (
        <div className={ classNames(classes.disclaimer, classes[`disclaimer--${mode}`]) }>
            <FontAwesomeIcon icon={ disclaimerIcons[mode] } />
            <p> { children } </p>
        </div>
    )
}

export default Disclaimer