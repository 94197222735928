import axios from 'axios';

import { getNewAccessToken } from './account.service';

import { removeItem } from 'utils/localStorage';

export const api = axios.create({
    baseURL: process.env.REACT_APP_GLOBAL_URL,
    headers: {
        common: {
            'Content-Type': 'application/json'
        },
        post: {
            'Content-Type': 'application/json'
        },
        put: {
            'Content-Type': 'application/json'
        },
        delete: {
            'Content-Type': 'application/json'
        }
    },
    timeout: 20000
});

export const scraper = axios.create({
    baseURL: process.env.REACT_APP_SCRAPER_URL,
    headers: {
        common: {
            'Content-Type': 'application/json'
        },
        post: {
            'Content-Type': 'application/json'
        }
    },
    timeout: 20000
});

api.interceptors.request.use(
    (config) => {
        const accessToken = localStorage.getItem("token");
        if (accessToken) {
            if (config.headers) config.headers.Authorization = `Bearer ${accessToken}`;
        }

        return config;
    },
    (error) => { 
      return Promise.reject(error);
    }
);

api.interceptors.response.use(
    (response) => response, 
    async (error) => {
        console.log('error', error);
        if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
            window.location.href = "/error?type=timeout";
        }

        if (error && error.response.data && error.response.data.timeout) {
            window.location.href = "/error?type=timeout";
        }

        if (error && error.response.data && error.response.data.permissionDenied) {
            window.location.href = "/error?type=permissionDenied";
        }
        
        if (error && error.response.data && error.response.data.jwtExpired) {
          try {
            const refreshToken = localStorage.getItem('refreshToken');
            if (refreshToken) {
                const res = await getNewAccessToken(refreshToken);
                localStorage.setItem('token', res?.data.token);
                error.config.headers['Authorization'] = `Bearer ${res?.data.token}`;
                return api(error.config);
            } else {
                const currentPath = window.location.pathname;

                removeItem('token');
                removeItem('refreshToken');
            
                window.location.href = `/signin?from=${encodeURIComponent(currentPath)}`;
            }
          } catch (refreshError) {
            const currentPath = window.location.pathname;

            removeItem('token');
            removeItem('refreshToken');
        
            window.location.href = `/signin?from=${encodeURIComponent(currentPath)}`;

            throw refreshError;
          }
        }

        return Promise.reject(error);
    }
)